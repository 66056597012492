<template>
  <div id="home">
        <navbar homepage="false"></navbar>
            <div class="newsletter-container">
                <div class="newsletter-left-column">
                    <h3>Archive</h3>
                    <ul>
                        <li>
                            2018
                            <ul>
                                <li><a class="archive-date" href="01-jan-2018">January</a></li>
                                <li><a class="archive-date" href="01-feb-2018">February</a></li>
                                <li><a class="archive-date" href="01-mar-2018">March</a></li>
                                <li><a class="archive-date" href="01-apr-2018">April</a></li>
                                <li><a class="archive-date" href="01-may-2018">May</a></li>
                                <li><a class="archive-date active" href="01-jun-2018">June</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="newsletter-right-column">
                    <div class="newsletter-right-column-content">
                        <h2>MUSKLE Monthly Newsletter #006</h2>
                        <p class="newsletter-subtitle">
                            "Ambient Temperature”
                        </p>
                        <p class="newsletter-date">
                            (June 2018)
                        </p>
                        <p class="newsletter-paragraph">
                            Who’s been keeping their training on track during the colder months? While it may be a lot nicer to stay in a warm bed during the chilly mornings, don’t let it be an excuse not to train. There are some things to consider when training in relatively (Australian) cooler temperatures which can reduce the risk of injury, boost your training results and make training enjoyable. These include:
                        </p>
                        <p class="newsletter-paragraph">
                            <ul class="styled-list">
                                <li>
                                    Warm up sufficiently! I usually like to extend my warm-up by a couple of minutes, to ensure blood is circulating properly through my muscles. Getting straight into a workout on cold muscles increases the risk of injury and reduces the muscles power output.
                                </li>
                                <li>
                                    Training in cooler environments has been proven to increase metabolic effects on the body, and in turn, helps burn calories! A shivering body can burn up to 500% more energy than a comfortable body (Although I don’t recommend sitting outside in the snow for that little bit of extra weight loss).
                                </li>
                                <li>
                                    Finish your workout with a black coffee or tea. Another way to kick-start your metabolism in the mornings is switching your fuel source to burn fats. This can be invoked by reducing your input of foods containing calories (stopping an insulin response). Plus, who doesn’t want a warm Hug Mug&tm; filled with caffeine?
                                </li>
                                <li>
                                    Having a training partner can really help motivate you to get out of that warm bed. If you’re training by yourself it’s very easy to make an excuse, and sleep in. Use a commitment device (Check out <a href="01-feb-2018">February’s newsletter</a>) with a friend to keep motivation levels high.
                                </li>
                            </ul>
                        </p>
                        <p class="newsletter-paragraph">
                            With that info in mind, you should be ready to tackle the cooler months and have an enjoyable workout without injury.
                        </p>
                        <p class="newsletter-paragraph">
                            I’m really excited about next month’s newsletter… Awesome information about the biggest killer in modern foods.
                        </p>
                    </div>
                </div>
            </div>
      </div>
</template>

<script>
import Navbar from '../components/NavBar.vue'

export default {
  name: 'app',
  components: {
    Navbar
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
#home {
  margin: 0;
  padding: 0;
}
  h2 {
      font-family: 'Roboto';
      margin-bottom: 25px;
      text-align: center;
  }
  p {
      font-family: 'Roboto', sans-serif;
  }
  ul {
      list-style-type: none;
  }
  li {
      padding: 5px 0;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
  }
  .archive-date {
      color: #000;
      text-decoration: none;
  }
  .archive-date:hover {
      text-decoration: underline;
  }
  .active {
      font-weight: 900;
  }
  .newsletter-container {
      margin-top: 125px;
      display: flex;
  }
  .newsletter-left-column {
      flex-grow: 1;
      padding: 10px;
      margin-top: 20px;
      margin-left: 20px;
      max-width: 160px;
  }
  .newsletter-right-column {
      display: flex;
      flex-grow: 5;
      justify-content: center;
      margin-right: 80px;
  }
  .newsletter-right-column-content {
      margin: 50px 100px;
      border: 3px double #ddd;
      padding: 0px 30px;
      max-width: 595px;
  }
  .newsletter-subtitle {
      font-size: 20px;
      font-weight: 300;
  }
  .newsletter-date {
      font-size: 14px;
      font-weight: 500;
  }
  .newsletter-paragraph {
      font-size: 16px;
      font-weight: 300;
      line-height: 28px;
  }
  .newsletter-preview {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      text-align: right;
      padding-top: 40px;
  }
  .italic {
      font-style: italic;
  }

  @media only screen and (max-width: 750px) {
      h2 {
          font-size: 18px;
      }
      .newsletter-container {
          flex-direction: column;
      }
      .newsletter-right-column {
          margin-right: 0;
      }
      .newsletter-right-column-content {
          margin: 10px 10px;
          padding: 0 10px;
      }
      .newsletter-subtitle {
          font-size: 16px;
      }
      .newsletter-date {
          font-size: 13px;
      }
      .newsletter-paragraph {
          font-size: 14px;
      }
      .newsletter-preview {
          font-size: 13px;
      }
  }
</style>
